// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAngnvbv2icMxCr7Ks4airVSVKGG10B9vI',
    authDomain: 'nickkrumholz-com.firebaseapp.com',
    databaseURL: 'https://nickkrumholz-com.firebaseio.com',
    projectId: 'nickkrumholz-com',
    storageBucket: 'nickkrumholz-com.appspot.com',
    messagingSenderId: '376529975601',
    appId: '1:376529975601:web:39938620a1f75948101810',
    measurementId: 'G-MWL9625PFM'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
