<mat-sidenav-container class="sidenav-container" autosize (swipeleft)="(screenWidth > 840) ? '' : close()">
  <mat-sidenav #drawer [autoFocus]="false" [opened]="screenWidth > 840" [mode]="(screenWidth > 840) ? 'side' : 'push'" (window:resize)="onResize($event)" (keydown.escape)="close()" class="sidenav-drawer">

    <mat-nav-list>
      <a mat-list-item (click)="scroll(title, title)" class="profile-spacing" [ngClass]="{'active-button': selectedItem == title}" matTooltip="About" matTooltipPosition="right" matTooltipClass="tooltip-primary">
        <picture>
          <source type="image/webp" srcset="./assets/img/profile.webp">
          <img height="150px" width="150px" class="profile-photo" alt="Profile photo of Nick outdoors" loading="lazy" src="./assets/img/profile.jpg">
        </picture>
      </a>
      <a mat-list-item (click)="scroll(experience, experience)" [ngClass]="{'active-button': selectedItem == experience}" matTooltip="Experience" matTooltipPosition="right" matTooltipClass="tooltip-primary">
        <span class="title">Experience</span>
      </a>
      <a mat-list-item (click)="scroll(education, education)" [ngClass]="{'active-button': selectedItem == education}" matTooltip="Education" matTooltipPosition="right" matTooltipClass="tooltip-primary">
        <span class="title">Education</span>
      </a>
      <a mat-list-item (click)="scroll(skills, skills)" [ngClass]="{'active-button': selectedItem == skills}" matTooltip="Skills" matTooltipPosition="right" matTooltipClass="tooltip-primary">
        <span class="title">Skills</span>
      </a>
      <a mat-list-item (click)="scroll(interests, interests)" [ngClass]="{'active-button': selectedItem == interests}" matTooltip="Interests" matTooltipPosition="right" matTooltipClass="tooltip-primary">
        <span class="title">Interests</span>
      </a>
    </mat-nav-list>

    <!-- <button (click)="scroll(title, title)" [ngClass]="{'active-button': selectedItem == title}" color="primary" mat-flat-button class="profile-spacing" matTooltip="About" matTooltipPosition="right" matTooltipClass="tooltip-primary">
      <picture>
        <source type="image/webp" srcset="./assets/img/profile.webp">
        <img class="profile-photo" alt="Profile photo of Nick outdoors" loading="lazy" src="./assets/img/profile.jpg">
      </picture>
    </button>

    <button (click)="scroll(experience, experience)" [ngClass]="{'active-button': selectedItem == experience}" color="primary" routerLinkActive="active-button" mat-flat-button class="sidenav-button" matTooltip="Experience" matTooltipPosition="right" matTooltipClass="tooltip-primary">
      <span class="title">Experience</span>
    </button>

    <button (click)="scroll(education, education)" [ngClass]="{'active-button': selectedItem == education}" color="primary" routerLinkActive="active-button" mat-flat-button class="sidenav-button" matTooltip="Education" matTooltipPosition="right" matTooltipClass="tooltip-primary">
      <span class="title">Education</span>
    </button>

    <button (click)="scroll(skills, skills)" [ngClass]="{'active-button': selectedItem == skills}" color="primary" routerLinkActive="active-button" mat-flat-button class="sidenav-button" matTooltip="Skills" matTooltipPosition="right" matTooltipClass="tooltip-primary">
      <span class="title">Skills</span>
    </button>

    <button (click)="scroll(interests, interests)" [ngClass]="{'active-button': selectedItem == interests}" color="primary" routerLinkActive="active-button" mat-flat-button class="sidenav-button" matTooltip="Interests" matTooltipPosition="right" matTooltipClass="tooltip-primary">
      <span class="title">Interests</span>
    </button> -->

  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content" (swiperight)="drawer.open()">

    <mat-toolbar [ngStyle]="{'display' : (screenWidth > 840) ? 'none' : 'flex'}" color="primary">
      <button (click)="drawer.open()" mat-icon-button class="menu-button">
        <mat-icon mat-list-icon aria-hidden="false" aria-label="Menu">
          menu
        </mat-icon>
      </button>
      <span class="title">Nick Krumholz</span>
    </mat-toolbar>

    <section #title class="content-section">
      <h1>Nick <span class="headline-color">Krumholz</span></h1>
      <div class="subheading-container">
        <div class="content-subheading">
          <mat-icon class="icon-spacer" mat-list-icon aria-hidden="false" aria-label="Position Title">
            code
          </mat-icon>
          Software Engineer
        </div>
        <div class="content-subheading">
          <mat-icon class="icon-spacer" mat-list-icon aria-hidden="false" aria-label="Current Location">
            place
          </mat-icon>
          Minneapolis
        </div>
        <div class="content-subheading">
          <mat-icon class="icon-spacer" mat-list-icon aria-hidden="false" aria-label="Contact Me">
            email
          </mat-icon>
          <a href="mailto:nick.krumholz@gmail.com">nick.krumholz@gmail.com</a>
        </div>
      </div>
      <div class="paragraph-spacer">My experiences have been in developing web-based applications that solve business problems. I'm curious about the way that things work and I enjoy challenging myself with the latest technology, however, I also know that there are times when an existing solution is the best tool for the job.</div>
      <div class="paragraph-spacer">Here are a few of the technologies and tools that I have a background in and a high interest in working with <strong>Angular</strong>, <strong>NodeJS</strong>, <strong>Shopify Dev Platform</strong>, and <strong>Google Cloud</strong></div>
      <div class="paragraph-spacer">
        <button class="social-buttons" mat-fab (click)="onNavigate('https://www.linkedin.com/in/krumholz/')">
          <mat-icon class="social-icons" fontSet="fab" fontIcon="fa-linkedin-in"></mat-icon>
        </button>
        <button class="social-buttons" mat-fab (click)="onNavigate('https://twitter.com/nickkrumholz')">
          <mat-icon class="social-icons" fontSet="fab" fontIcon="fa-twitter"></mat-icon>
        </button>
        <button class="social-buttons" mat-fab (click)="onNavigate('https://github.com/krumholz')">
          <mat-icon class="social-icons" fontSet="fab" fontIcon="fa-github"></mat-icon>
        </button>
      </div>
    </section>
    <hr>
    <section #experience class="content-section">
      <h2>Experience</h2>
      <div class="job-details">
        <div class="title-row">
          <h3>Back-End Engineer</h3>
          <div class="job-dates">January 2020 - Present</div>
        </div>
        <h4>Forever Ware</h4>
        <ul>
          <li>Guided the software development strategy and execution</li>
          <li>Technology implementations that I led included: <strong>Angular 10</strong> and a <strong>PWA</strong>, <strong>GitHub Actions</strong> for <strong>CI/CD</strong>, <strong>Node.js</strong> using <strong>Express</strong> to power an <strong>API</strong>, with a <strong>Firebase NoSQL</strong> database on <strong>Google Cloud</strong></li>
        </ul>
      </div>
      <div class="job-details">
        <div class="title-row">
          <h3>Back-End Engineer</h3>
          <div class="job-dates">March 2017 - December 2019</div>
        </div>
        <h4>Mischief Studios</h4>
        <ul>
          <li>Wrote reporting and debugging scripts using <strong>Python</strong> for greater visibility into inventory tracking across systems and better foresight for the potential end of year inconsistencies</li>
          <li>Created a mesh wifi network, set up <strong>G Suite</strong>, and used <strong>Square</strong> and <strong>BigCommerce</strong> to build unified e-commerce & in-store sales channel</li>
        </ul>
      </div>
      <div class="job-details">
        <div class="title-row">
          <h3>Software Engineer</h3>
          <div class="job-dates">September 2011 - September 2016</div>
        </div>
        <h4>Target Corporation</h4>
        <ul>
          <li>Created a proprietary content creation and management system for Target.com within an agile scrum team</li>
          <li>Implemented <strong>MongoDB, PHP, HTML, CSS,</strong> and a custom configuration of <strong>JIRA</strong> as a database to build a workflow engine</li>
          <li>Wrote and executed <strong>PHP</strong> scripts for identifying errors throughout various databases</li>
          <li>Later, I worked to expand the cms to hundreds of internal stakeholders and built <strong>Confluence</strong> pages for quick client documentation</li>
          <li>Developed digital content for our customers in the form of large single-page and multi-page experiences</li>
          <li>Worked collectively with our Bangalore team to lead the <strong>HTML/JS</strong> development and execution of 90+ marketing campaigns across Target.com</li>
          <li>Wrote functions to clean and analyze data within <strong>Excel</strong> to save months of work of manually copying and transferring data within teams</li>
        </ul>
      </div>
      <!-- <div class="job-details">
        <div class="title-row">
          <h3>Store Manager</h3>
          <div class="job-dates">March 2005 - October 2008</div>
        </div>
        <h4>Sprint</h4>
        <div class="paragraph-spacer">Achieved regional salesman of the year for 2 years by recognizing customer needs and addressing them with open and honest dialogue. Built a sales team of 4 that became the highest grossing store in a 12 store region</div>
      </div> -->
    </section>
    <hr>
    <section #education class="content-section">
      <h2>Education</h2>
      <div class="job-details">
        <div class="title-row">
          <h3>Winona State University</h3>
          <div class="job-dates">August 2007 - May 2011</div>
        </div>
        <h4>Bachelor of Science</h4>
        <div class="paragraph-spacer">Computer Science - Major<br>
          Business Administration - Minor</div>
      </div>
    </section>
    <hr>
    <section #skills class="content-section">
      <h2>Skills</h2>
      <div class="job-details">
        <h4>Programming Languages & Tools</h4>
        <div class="paragraph-spacer">
          Angular, NodeJS, Google Cloud (Firebase)<br>
          Python, HTML, CSS & MongoDB<br>
          Git, Pipenv, Flask, SQLAlchemy<br>
          Google Cloud (App Engine & Cloud SQL)<br>
          PHP, MySQL<br>
          Agile Development & Scrum
        </div>
      </div>
    </section>
    <hr>
    <section #interests class="content-section">
      <h2>Interests</h2>
      <div class="job-details">
        <!-- <h4>Investing</h4> -->
        <div class="paragraph-spacer">Besides programming, I am an avid outdoors person. Visiting national parks and traveling to faraway places is especially intriguing to me. This year I have traveled to 3 national parks, which I hope to expand upon in the following year. At home, I like to read, follow the markets and spend time with my family.</div>
      </div>
    </section>

  </mat-sidenav-content>
</mat-sidenav-container>
