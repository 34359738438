import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('drawer', {static: false}) drawer: MatSidenav;
  screenWidth: number;
  selectedItem: string;

  constructor() { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  close(): void {
    this.drawer.close();
  }

  refresh(): void {
    window.location.reload();
  }

  onResize(event): void {
    this.screenWidth = event.target.innerWidth;
  }

  scroll(el: HTMLElement, selected: string): void {
    if (this.screenWidth < 840) {
      this.drawer.close();
    }
    this.selectedItem = selected;
    el.scrollIntoView({ behavior: 'smooth' });
  }

  onNavigate(url: string): void {
    window.open(url, '_blank');
  }

}
