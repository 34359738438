import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule, Injectable } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialDesignModule } from './modules/material-design/material-design/material-design.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import * as Hammer from 'hammerjs';
import { HomeComponent } from './components/home/home.component';
import { NavigationComponent } from './components/navigation/navigation.component';

//  https://stackoverflow.com/questions/41017202/vertical-scroll-is-not-working-with-hammerjs-and-angular2
// https://stackoverflow.com/a/42425301/1311882
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      pinch: { enable: false },
      rotate: { enable: false },
      cssProps: { userSelect: 'text' },
    });
    return mc;
  }
  // overrides = {
  //   // Fix for scroll and pull to refresh not working
// https://stackoverflow.com/questions/41017202/vertical-scroll-is-not-working-with-hammerjs-and-angular2
  //   pinch: { enable: false },
  //   rotate: { enable: false },
  //   pan: { enable: false },
  //   // cssProps: { userSelect: 'text' },
  // } as any;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialDesignModule,
    FormsModule,
    HttpClientModule,
    HammerModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
